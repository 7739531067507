body {
  margin: 0;
  font-family: sans-serif;
  background-color: #d6eeff;
}

/*table, th, td {
   border: 1px solid black;
}*/

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container > div {
  background-color: #bfe4ff;
  margin: 10px;
  width: 300px;
  text-align: left;
  border: 2px solid black;
  border-radius: 5px;
}

.topnav {
  background-color: #333;
  overflow: hidden;
}

.button {
  float: left;
  display: block;
  color: #f2f2f2;
  background-color: #333;
  text-align: center;
  padding: 14px 16px;
  font-size: 17px;
  border: none;
  outline: none;
}

.button:hover,
.dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.active {
  background-color: #51b7ff;
  color: white;
}

.dropdown {
  float: right;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
}

.dropdown .headerbtn {
  font-size: 17px;
  font-weight: bold;
  border: none;
  outline: none;
  color: black;
  padding: 14px 16px;
  background-color: inherit;
}

.dropdown-content-invisible {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  max-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-visible {
  display: block;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  max-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-visible .button {
  color: black;
  background-color: #f9f9f9;
  display: block;
  width: 100%;
  padding-inline-start: 50px;
  text-align: left;
}

.dropdown-content-visible .button:hover {
  background-color: #ddd;
}

.dropdown-content-visible .active {
  background-color: #51b7ff;
  color: white;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: lightblue;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  white-space: pre;

  /* Position the tooltip text  */
  position: absolute;
  z-index: 1;
  top: -200%;
  left: 120%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent lightblue transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
